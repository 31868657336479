import React from 'react'
import styles from '../Proj5Folder/Proj5.module.css'
import em1 from '../../Images/em1.jpg'
import hospital2 from '../../Images/hospital2.png'
import hospital3 from '../../Images/hospital3.png'
import hospital4 from '../../Images/hospital4.jpg'
import azema from '../../Images/azema1.jpg'
import hospital6 from '../../Images/hospital6.png'
import hospital7 from '../../Images/hospital7.png'
import portsaid from '../../Images/portsaid.jpg'
import sa from '../../Images/sa1.jpg'
import dental from '../../Images/dental1.jpg'
import smart from '../../Images/smart1.jpg'
import hospital from '../../Images/hhospital1 (1).jpg'
import godness from '../../Images/godness1.jpg'
import eng from '../../Images/eng1.jpg'
import dar from '../../Images/dar1.jpg'
import kayan from '../../Images/kayan1.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react'




export default function Proj5Edu() {
  useEffect(() => {
    AOS.init({duration: 1500})
  
  }, [])
  return (

    <>
       <section className='my-5 py-3 '>

       <div  className={`${styles.bgCard} container my-5 py-5 `}>
        
        <h2 className='text-center fs-2 fw-bolder position-relative mainColor'>  Medical And Educational Buildings   
     
        <div className="shortline mt-2"></div>
        <div className="longline"></div>
        <div className="shortline mb-2"></div>
        </h2>
   
        <div className="row">


        <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/sahrgt" className={`${styles.linkPro}`}> 
            <img src={sa} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Sahrgt Hospital </p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/dental" className={`${styles.linkPro}`}> 
            <img src={dental} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '> Dental Medical Center</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/smartCare" className={`${styles.linkPro}`}> 
            <img src={smart} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>   Smart Care Hospital in Mokattam</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/Hospital5" className={`${styles.linkPro}`}> 
            <img src={azema} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Al Azema Hospital </p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/Hospital4" className={`${styles.linkPro}`}> 
            <img src={hospital4} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Hana Medical Center</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/muscular" className={`${styles.linkPro}`}> 
            <img src={hospital} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '> Muscular Dystrophy Hospital</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
          <Link to="/Hospital2" className={`${styles.linkPro}`}> 
          <img src={hospital2} alt="achegy" className={`${styles.imgSizeProj}`}/>
          <p className='text-center fw-bold mt-3 '>ABHA Hospital , Abha , KSA</p>
          </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
          <Link to="/Hospital3" className={`${styles.linkPro}`}> 
          <img src={hospital3} alt="achegy" className={`${styles.imgSizeProj}`}/>
          <p className='text-center fw-bold mt-3 '>NRC Hospital , Jeddah , KSA</p>
          </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/godnessHospital" className={`${styles.linkPro}`}> 
            <img src={godness} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Your gateway to goodness Hospital</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/darElShifaHospital" className={`${styles.linkPro}`}> 
            <img src={dar} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>  Dar Al Shifa Hospital</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/kayan" className={`${styles.linkPro}`}> 
            <img src={kayan} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>  Kayan Hospital</p>
            </Link>  
            </div>
        
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
          <Link to="/Hospital6" className={`${styles.linkPro}`}> 
          <img src={hospital6} alt="achegy" className={`${styles.imgSizeProj}`}/>
          <p className='text-center fw-bold mt-3 '>Al Osra Hospital </p>
          </Link>  
          </div>
          <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/Hospital7" className={`${styles.linkPro}`}> 
            <img src={hospital7} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>October International Hospital </p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/Hospital1" className={`${styles.linkPro}`}> 
            <img src={em1} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Al Shorouk Hospital</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/portsaid" className={`${styles.linkPro}`}> 
            <img src={portsaid} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Port said School</p>
            </Link>  
            </div>
            <div className="col-md-4 my-3 text-center" data-aos='fade-up'>
            
            <Link to="/eng" className={`${styles.linkPro}`}> 
            <img src={eng} alt="achegy" className={`${styles.imgSizeProj}`}/>
            <p className='text-center fw-bold mt-3 '>Engineers Restaurant </p>
            </Link>  
            </div>
     
           
        </div>
       </div>

    </section>

    
    </>
   
  )
}
